@tailwind base;
@tailwind components;
@tailwind utilities;

html,
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  -webkit-user-select: none;
  user-select: none;
  background-color: rgb(219 234 254)
}

@keyframes drive {
  0% {
    transform: translateX(1000%);
  }
  50% {
    transform: translateX(400%);
  }
  75% {
    transform: translateX(200%);
  }
  90% {
    transform: translateX(130%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-drive {
  transform: translateX(100%);
  animation-name: drive;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}


